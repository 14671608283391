import React, { useEffect } from "react";
import "./landing.css";
import Cover from "./Cover";
import AutomateFeature from "./AutomateFeature";
import Features from "./Features";
import Testimonial from "./Testimonial";
import Faqs from "./Faqs";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";
import SecurityFeatures from "./SecurityFeatures";
import LandingPricing from "./LandingPricing";
import TopNavbar from "./Header/TopNavbar";
import BottomHero from "./Header/BottomHero";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <script>
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
              var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
              s1.async=true;
              s1.src='https://embed.tawk.to/67987da53a8427326075ae92/1iilqlk4r';
              s1.charset='UTF-8';
              s1.setAttribute('crossorigin','*');
              s0.parentNode.insertBefore(s1,s0);
            })();
          `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            name: "SalonBoost",
            url: "https://salonboost.online",
            image: "https://salonboost.online/logo.svg",
            description:
              "SalonBoost is India's best salon management software designed to automate bookings, client management, billing, and marketing for salons and spas.",
            applicationCategory: "BusinessApplication",
            operatingSystem: "Web",
            offers: {
              "@type": "Offer",
              price: "999",
              priceCurrency: "INR",
              availability: "https://schema.org/InStock",
              url: "https://salonboost.online/compare-salon-softwares",
            },
            provider: {
              "@type": "Organization",
              name: "SalonBoost Salon Software",
              url: "https://salonboost.online",
              logo: "https://salonboost.online/logo.svg",
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+917090515255",
                contactType: "customer service",
                areaServed: "IN",
                availableLanguage: ["en", "hi"],
              },
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.8",
              reviewCount: "1500",
            },
            review: [
              {
                "@type": "Review",
                author: {
                  "@type": "Person",
                  name: "Rahul Sharma",
                },
                reviewRating: {
                  "@type": "Rating",
                  ratingValue: "5",
                },
                reviewBody:
                  "SalonBoost has completely transformed my salon business. The automated appointment scheduling is a game changer!",
              },
            ],
            areaServed: {
              "@type": "Country",
              name: "India",
            },
            audience: {
              "@type": "Audience",
              audienceType: "Salon Owners, Spa Owners, Beauty Professionals",
            },
            mainEntity: {
              "@type": "FAQPage",
              mainEntity: [
                {
                  "@type": "Question",
                  name: "What is the best salon management software in India?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "SalonBoost is India's leading salon management software designed to automate bookings, billing, and client management.",
                  },
                },
                {
                  "@type": "Question",
                  name: "How does SalonBoost help salon owners?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "SalonBoost simplifies salon operations with features like automated appointment booking, customer tracking, and marketing automation.",
                  },
                },
                {
                  "@type": "Question",
                  name: "Does SalonBoost support WhatsApp appointment reminders?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "Yes, SalonBoost includes automated WhatsApp reminders to reduce no-shows and improve customer engagement.",
                  },
                },
                {
                  "@type": "Question",
                  name: "Is there a free trial for SalonBoost?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "Yes, we offer a 15-day free trial for salon owners to experience the features of SalonBoost.",
                  },
                },
                {
                  "@type": "Question",
                  name: "Can SalonBoost be used for multiple salon branches?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "Yes, SalonBoost supports multi-location salons, allowing owners to manage multiple branches from a single dashboard.",
                  },
                },
                {
                  "@type": "Question",
                  name: "Is salonboost Free Salon management software?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "No, We have free trial and our cost is best in the industry for the value we provide.",
                  },
                },
                {
                  "@type": "Question",
                  name: "Is SalonBoost salon management software secure?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "Yes, SalonBoost uses advanced encryption, secure data storage, and regular security audits to ensure maximum protection of salon and client data.",
                  },
                },
                {
                  "@type": "Question",
                  name: "How does SalonBoost protect customer data?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "SalonBoost follows strict data privacy policies and uses SSL encryption to protect customer data from breaches.",
                  },
                },
                {
                  "@type": "Question",
                  name: "Does SalonBoost comply with data protection laws?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "Yes, SalonBoost complies with industry data protection regulations, ensuring secure handling of customer information.",
                  },
                },
                {
                  "@type": "Question",
                  name: "Can I control access levels for my salon staff?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "Yes, SalonBoost allows role-based access control, so you can set permissions for different staff members.",
                  },
                },
                {
                  "@type": "Question",
                  name: "How often is SalonBoost data backed up?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "SalonBoost performs automatic daily backups to prevent data loss and ensure business continuity.",
                  },
                },
                {
                  "@type": "Question",
                  name: "What happens if there is a system failure?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "SalonBoost has a disaster recovery system in place, ensuring your data remains safe even in the event of technical failures.",
                  },
                },
              ],
            },
          })}
        </script>
      </Helmet>
      <div className="montserrat_Font">
        <TopNavbar />
        <BottomHero />
        {/* <Topbar /> */}
        <div className="mt-5 landing-page-container">
          <Cover />
          <AutomateFeature />
          <Features />
          <LandingPricing />
          <Testimonial />
          <Faqs />
          <SecurityFeatures />
        </div>
        <Footer showTrustpilot={false} />
      </div>
    </>
  );
};

export default Home;
