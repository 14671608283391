import React from "react";
import TopNavbar from "./Header/TopNavbar";
import Footer from "./Footer";
import BottomHero from "./Header/BottomHero";

const Dummy = () => {
  return (
    <>
      <TopNavbar />
      <BottomHero />
      <Footer />
    </>
  );
};

export default Dummy;
