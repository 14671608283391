import {
  CContainer,
  CRow,
  CCol,
  CTable,
  CTableRow,
  CTableDataCell,
  CTableBody,
  CCard,
  CCardBody,
  CCardText,
  CButton,
  CCardTitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React, { useEffect } from "react";
import { cilCheckAlt, cilX } from "@coreui/icons";
import Footer from "../Footer";
import { Helmet } from "react-helmet-async";
import TopNavbar from "../Header/TopNavbar";
import BottomHero from "../Header/BottomHero";

const Pricing = () => {
  const appointments = [
    {
      featureName: "Unlimited Appointments",
      status: true,
    },
    {
      featureName: "Online Booking Management",
      status: false,
    },
    {
      featureName: "Appointment Calendar",
      status: true,
    },
    {
      featureName: "Staff Roster",
      status: true,
    },

    {
      featureName: "Automated Service Reminders",
      status: true,
    },
  ];

  const clientManagement = [
    {
      featureName: "Unlimted Clients",
      status: true,
    },
    {
      featureName: "Customer Feedback System",
      status: true,
    },
    {
      featureName: "Customer History Management",
      status: true,
    },
    {
      featureName: "Rewards /Loyality Points",
      status: true,
    },
    {
      featureName: "Consultation / Consent Forms",
      status: true,
    },
    {
      featureName: "Appointment History",
      status: true,
    },

    {
      featureName: "Documents Management",
      status: true,
    },
    {
      featureName: "Notes Management",
      status: true,
    },
  ];

  const pos = [
    {
      featureName: "Point Of Sale (POS)",
      status: true,
    },
    {
      featureName: "Expenses Management",
      status: true,
    },
    {
      featureName: "Cash Register",
      status: true,
    },
    {
      featureName: "Mobile point of sale",
      status: true,
    },
    {
      featureName: "Products & Package Management",
      status: true,
    },
  ];

  const businessanalysisreports = [
    {
      featureName: "Staff Reports",
      status: true,
    },
    {
      featureName: "Sales Reports",
      status: true,
    },
    {
      featureName: "Appointment Reports",
      status: true,
    },
  ];

  const marketing = [
    {
      featureName: "Coupons Management",
      status: true,
    },

    {
      featureName: "Membership Subscription",
      status: false,
    },

    {
      featureName: "Memberships & Ewallet Management",
      status: true,
    },

    {
      featureName: "Referral System",
      status: true,
    },
  ];

  const onboardingandsupportfromsalonist = [
    {
      featureName: "Dedicated Account Manager",
      status: true,
    },
    {
      featureName: "Data Migration From Your Old System",
      status: true,
    },
    {
      featureName: "Online and call back support",
      status: true,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <script>
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
              var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
              s1.async=true;
              s1.src='https://embed.tawk.to/67987da53a8427326075ae92/1iilqlk4r';
              s1.charset='UTF-8';
              s1.setAttribute('crossorigin','*');
              s0.parentNode.insertBefore(s1,s0);
            })();
          `}
        </script>
      </Helmet>
      {/* <Topbar /> */}
      <TopNavbar />
      <BottomHero />
      <CContainer className="mt-5 landing-page-container d-flex flex-column">
        <CRow className="w-lg-75 justify-content-center m-auto">
          <CCol sm={6}>
            <h2 className="col-lg-6 col-md-3 col-sm-12 ms-5">Pricing that Fits Every Business</h2>
          </CCol>
          <CCol sm={3}>
            <CCard>
              <CCardBody>
                <CCardTitle>Standard</CCardTitle>
                <CCardText>Some quick example text to</CCardText>
                <CButton color="primary" href="#">
                  button
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm={3}>
            <CCard className="mmt-2">
              <CCardBody>
                <CCardTitle>Premium</CCardTitle>
                <CCardText>Some quick example text to</CCardText>
                <CButton color="primary" href="#">
                  button
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <CRow className="justify-content-center">
          <CCol md={8}>
            <h2 className="mt-4">Appointments</h2>
            <CTable>
              <CTableBody>
                {appointments.map((appointment, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{appointment.featureName}</CTableDataCell>
                    <CTableDataCell style={{ paddingRight: "70px" }}>
                      {appointment.status ? (
                        <CIcon icon={cilCheckAlt} className="text-success " size="xl" />
                      ) : (
                        <CIcon icon={cilX} className="text-danger" size="xl" />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      <CIcon icon={cilCheckAlt} className="text-success" size="xl" />
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>

        <CRow className="justify-content-center">
          <CCol md={8}>
            <h2 className="mt-4">Client Management</h2>
            <CTable>
              <CTableBody>
                {clientManagement.map((client, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{client.featureName}</CTableDataCell>
                    <CTableDataCell style={{ paddingRight: "70px" }}>
                      {client.status ? (
                        <CIcon icon={cilCheckAlt} className="text-success " size="xl" />
                      ) : (
                        <CIcon icon={cilX} className="text-danger" size="xl" />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      <CIcon icon={cilCheckAlt} className="text-success" size="xl" />
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>

        <CRow className="justify-content-center">
          <CCol md={8}>
            <h2 className="mt-4">POS</h2>
            <CTable>
              <CTableBody>
                {pos.map((point, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{point.featureName}</CTableDataCell>
                    <CTableDataCell style={{ paddingRight: "70px" }}>
                      {point.status ? (
                        <CIcon icon={cilCheckAlt} className="text-success" size="xl" />
                      ) : (
                        <CIcon icon={cilX} className="text-danger" size="xl" />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      <CIcon icon={cilCheckAlt} className="text-success" size="xl" />
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>

        <CRow className="justify-content-center">
          <CCol md={8}>
            <h2 className="mt-4">Business Analysis Reports</h2>
            <CTable>
              <CTableBody>
                {businessanalysisreports.map((business, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{business.featureName}</CTableDataCell>
                    <CTableDataCell style={{ paddingRight: "70px" }}>
                      {business.status ? (
                        <CIcon icon={cilCheckAlt} className="text-success " size="xl" />
                      ) : (
                        <CIcon icon={cilX} className="text-danger" size="xl" />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      <CIcon icon={cilCheckAlt} className="text-success" size="xl" />
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>

        <CRow className="justify-content-center">
          <CCol md={8}>
            <h2 className="mt-4">Marketing</h2>
            <CTable>
              <CTableBody>
                {marketing.map((market, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{market.featureName}</CTableDataCell>
                    <CTableDataCell style={{ paddingRight: "70px" }}>
                      {market.status ? (
                        <CIcon icon={cilCheckAlt} className="text-success " size="xl" />
                      ) : (
                        <CIcon icon={cilX} className="text-danger" size="xl" />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      <CIcon icon={cilCheckAlt} className="text-success" size="xl" />
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>

        <CRow className="justify-content-center mb-4">
          <CCol md={8}>
            <h2 className="mt-4">On Boarding And Support From Salonist</h2>
            <CTable>
              <CTableBody>
                {onboardingandsupportfromsalonist.map((onboard, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{onboard.featureName}</CTableDataCell>
                    <CTableDataCell style={{ paddingRight: "70px" }}>
                      {onboard.status ? (
                        <CIcon icon={cilCheckAlt} className="text-success " size="xl" />
                      ) : (
                        <CIcon icon={cilX} className="text-danger" size="xl" />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      <CIcon icon={cilCheckAlt} className="text-success" size="xl" />
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </CCol>
        </CRow>
      </CContainer>
      <Footer showTrustpilot={false} />
    </>
  );
};

export default Pricing;
