import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const TopNavbar = () => {
  const navigate = useNavigate();
  const [isNavVisible, setIsNavVisible] = useState(false); // State to manage mobile menu visibility

  const phoneNumber = "7090515255";
  const message = "From Website - I am interested in salonboost offer";

  const handleLogoClick = () => {
    navigate("/");
  };

  const openWhatsApp = () => {
    const whatsappLink = `https://wa.me/${phoneNumber.replace(
      /[-+ ]/g,
      ""
    )}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const closeNav = () => {
    setIsNavVisible(false); // Close mobile menu when a link is clicked
  };

  return (
    <header>
      <nav className="navbar">
        <img
          src="images/logo.svg"
          alt="SalonBoost Logo"
          className="logo ps-4"
          onClick={handleLogoClick}
          style={{
            cursor: "pointer",
            transition: "opacity 0.3s ease",
            transform: "scale(1.5)",
          }}
        />

        {/* Hamburger Menu Icon */}
        <div className={`hamburger ${isNavVisible ? "active" : ""}`} onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        {/* Navigation Menu */}
        <ul className={`nav-menu ${isNavVisible ? "active" : ""}`}>
          <li>
            <ScrollLink to="features" smooth={true} duration={500} onClick={closeNav}>
              Features
            </ScrollLink>
          </li>
          <li className="">
            <ScrollLink to="pricing" onClick={closeNav}>
              Pricing
            </ScrollLink>
          </li>
          <li>
            <Link to="/compare-salon-softwares" onClick={closeNav}>
              Compare Salon Softwares
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                openWhatsApp();
                closeNav();
              }}
              className="btn"
            >
              Book Free Demo
            </Link>
          </li>
          <li>
            <Link to="/register" className="btn" onClick={closeNav}>
              Start Free Trial
            </Link>
          </li>
          <li className="mmb-1">
            <Link to="/login" className="btn" onClick={closeNav}>
              Login
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default TopNavbar;
