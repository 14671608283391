import React from "react";
import { CRow, CCol, CImage } from "@coreui/react";
import landing5 from "../../assets/images/landing/Reward-points.webp";
import landing6 from "../../assets/images/landing/ewallet.webp";
import landing7 from "../../assets/images/landing/Automatedservice.webp";
const Features = () => {
  return (
    <div className="p-lg-5 w-75 m-auto">
      <h1 className=" text-center mb-4 mmt-3">
        SalonBoost: Best Salon & Spa Management App to Retain More Clients
      </h1>

      <CCol>
        {/* First Row */}
        <CRow className=" mt-4 d-flex align-items-center fs-5 lh-base ">
          <CCol xs={12} md={6} className="text-center">
            <CImage
              src={landing5}
              fluid
              alt="Advanced Client Management"
              className="mt-3"
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "500px",
                margin: "0 auto",
              }}
            />
          </CCol>
          <CCol className="mmt-2" xs={12} md={6}>
            <h3>Reward Points</h3>
            <p>
              With SalonBoost, your clients earn reward points every time they
              visit, which they can redeem for cash discounts on future
              appointments. Boost client retention by giving them more reasons
              to come back! There will be a automated whatsapp reminder to the
              clients on the balance reward points to motivate them to visit
              again.
            </p>
          </CCol>
        </CRow>

        {/* Second Row */}
        <div
        // style={{
        //   backgroundColor: "rgba(211, 237, 252, 0.68)",
        //   padding: "2rem",
        //   width:"100vw"
        // }}
        >
          <CRow className="mt-4 d-flex align-items-center fs-5 lh-base p-2">
            <CCol xs={12} md={6} className="text-center">
              <CImage
                src={landing6}
                fluid
                alt="Seamless Reporting & Analytics"
                className="mt-3"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "500px",
                  margin: "0 auto",
                }}
              />
            </CCol>
            <CCol className="mmt-2" xs={12} md={6}>
              <h3>e-Wallet & Packages</h3>
              <p>
                SalonBoost makes payments seamless with an e-wallet system,
                offering a hassle-free payment experience for both you and your
                clients. Plus, sell customized service bundles or packages,
                tailored to meet individual client needs.
              </p>
            </CCol>
          </CRow>
        </div>

        {/* Third Row */}

        <CRow className="mt-4 d-flex align-items-center fs-5 lh-base">
          <CCol xs={12} md={6} className="text-center">
            <CImage
              src={landing7}
              fluid
              alt="Automated Scheduling"
              className="mt-3"
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: "500px",
                margin: "0 auto",
              }}
            />
          </CCol>
          <CCol className="mmt-2" xs={12} md={6}>
            <h3>Automated Service Reminders</h3>
            <p>
              Never miss an opportunity to bring clients back! SalonBoost sends
              automatic service reminders to your clients based on their last
              visit, making it easy to keep them coming back for their next
              appointment.
            </p>
          </CCol>
        </CRow>
      </CCol>
    </div>
  );
};

export default Features;
