import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilEnvelopeOpen } from "@coreui/icons";
import { useToastContext } from "../../../layout/ToastContext";
import * as Styled from "../register/Style";
const baseURL = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const { showToast } = useToastContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState("");

  useEffect(() => {
    console.log(window.location.pathname);
    const hasReloaded = sessionStorage.getItem("hasReloaded");
    if (!hasReloaded) {
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  }, [window.location.pathname]);

  const handleSubmit = async (e) => {
    setErrorData("");
    e.preventDefault();
    let validationFailed = false;

    if (!password) {
      setErrorData("Enter password");
      validationFailed = true;
    }
    if (!email) {
      setErrorData("Enter email address");
      validationFailed = true;
    }

    if (validationFailed) {
      return;
    }
    const lowercaseEmail = email.toLowerCase();
    setIsLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: lowercaseEmail, password }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data) {
          localStorage.setItem("userdata", JSON.stringify(data));
          showToast(data.message, "primary", { autohide: 3000 });
          navigate("/dashboard", { state: { data } });
        } else {
          navigate("/otpverify", { state: { email } });
          showToast(data.message, "primary", { autohide: 3000 });
        }
      } else {
        const errorData = await response.json();
        showToast(errorData.error, "danger", { autohide: 5000 });
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
      showToast("Network error. Please try again.", "danger", {
        autohide: 5000,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilEnvelopeOpen} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Enter registered email address *"
                        autoComplete="email"
                        id="email"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Enter password *"
                        autoComplete="current-password"
                        id="password"
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </CInputGroup>
                    {errorData && (
                      <div style={{ color: "red", paddingBottom: "10px" }}>{errorData}</div>
                    )}
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" onClick={handleSubmit}>
                          {isLoading ? (
                            <>
                              <Styled.LoadingIcon />
                            </>
                          ) : (
                            `Login`
                          )}
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <Link to="/forgot">
                          <CButton color="link" className="px-0">
                            Forgot password?
                          </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5"
                // style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Sign up with SalonBoost and start your one month free trial. No credit card
                      needed, no commitments, and no long-term contracts.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
