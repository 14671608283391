import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const BottomHero = () => {
  const navigate = useNavigate();
  // const [demoModalVisible, setDemoModalVisible] = useState(false);

 const phoneNumber = "+917090515255";
  const message = "From Website - I am interested in salonboost offer";
  const openWhatsApp = () => {
    const whatsappLink = `https://wa.me/${phoneNumber.replace(
      /[-+ ]/g,
      ""
    )}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };



  // // Only render if not on WhySalonBoost page
  // if (window.location.pathname === "/why-salonboost") {
  //   return null;
  // }

  return (
    <section className="hero">
      <div className="hero-content text-center">
        <h2 className=" mt-2 mb-1 mmt-1">Boost Your Salon Sales with SalonBoost!</h2>
        <p className="mb-1">
          Simplify your salon management, automate bookings, and grow your
          client base effortlessly.
        </p>
        <div className="d-flex justify-content-center gap-2">
          <button
            className="cta-button btn btn-sm mmt-1"
            onClick={() => {
              // setDemoModalVisible(true); 
              openWhatsApp(); 
            }}
          >
            Book a Free Demo
          </button>
          <button
            className="cta-button btn btn-sm mmt-1"
            id="freeTrialButton"
            onClick={() => navigate("/register")}
          >
            Start Free Trial
          </button>
          <button
            className="cta-button btn btn-sm mmt-1"
            id="loginButton"
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </div>
      </div>
    </section>
  );
};

export default BottomHero;
