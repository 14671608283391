import React, { useEffect } from "react";
import {
  CRow,
  CCardBody,
  CCard,
  CCol,
  CImage,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CCardTitle,
  CCardText,
} from "@coreui/react";
// import Topbar from "../Topbar";
import Footer from "../Footer";
import landing from "../../../assets/images/landing/1.PNG";
import landing2 from "../../../assets/images/landing/2.PNG";
import landing3 from "../../../assets/images/landing/3.PNG";
import Whysalonboost from "../../../assets/images/landing/Whysalonboost.webp";
import { Helmet } from "react-helmet-async";
import TopNavbar from "../Header/TopNavbar";
import "../landing.css";

const WhySalonboost = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const phoneNumber = "+917090515255";
  const message = "From Website - I am interested in salonboost offer";
  const openWhatsApp = () => {
    const whatsappLink = `https://wa.me/${phoneNumber.replace(
      /[-+ ]/g,
      ""
    )}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
              (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/67987da53a8427326075ae92/1iilqlk4r';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
              })();
          `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            name: "SalonBoost - Best Salon Management Software",
            image: "https://salonboost.online/logo.svg",
            description:
              "See why SalonBoost is the best salon management software in India. Compare SalonBoost with top competitors like MioSalon, Zylu and Marg Software.",
            brand: {
              "@type": "Brand",
              name: "SalonBoost",
            },
            url: "https://salonboost.online/compare-salon-softwares",
            review: [
              {
                "@type": "Review",
                author: "Salon Owner",
                reviewRating: {
                  "@type": "Rating",
                  ratingValue: "5",
                },
                reviewBody:
                  "SalonBoost has saved us hours every week! The appointment scheduling is far better than Zenoti or MioSalon.",
              },
            ],
            offers: {
              "@type": "Offer",
              url: "https://salonboost.online/compare-salon-softwares",
              price: "999",
              priceCurrency: "INR",
              availability: "https://schema.org/InStock",
            },
            isSimilarTo: [
              {
                "@type": "Product",
                name: "MioSalon",
                url: "https://www.miosalon.com/",
              },
              {
                "@type": "Product",
                name: "Zylu",
                url: "https://www.zylu.co/",
              },
              {
                "@type": "Product",
                name: "Marg Software",
                url: "https://margcompusoft.com/",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="montserrat_Font">
        <TopNavbar className="mb-4" />
        <div className="mt-5 pt-5 w-lg-75 pb-5 m-auto">
          <h1 className="fs-1 mt-3 mb-4 mms-1">
            Maximize Your Profit: Boost Your Revenue by 30% with SalonBoost
          </h1>
          <div className="p-lg-5 mms-1 pme-3">
            <CRow>
              <CCol>
                <CImage
                  src={Whysalonboost}
                  fluid
                  alt="Landing Image 1"
                  className=" mx-auto d-block"
                  style={{
                    // maxWidth: "70%", // Medium size for web view
                    width: "100%", // Full width for mobile
                    height: "auto",
                  }}
                />
              </CCol>
            </CRow>
            <CRow className="mt-2 fs-5 lh-base">
              <h2 className=" mt-3 montserrat_Font">Why Choose SalonBoost Over Competitors?</h2>
              <p>
                Running a salon or spa involves managing many tasks — from appointments to marketing
                to customer communication. While there are many salon management software options
                available, not all provide the same value. Here’s why{" "}
                <span className="fw-bolder">SalonBoost</span> is the{" "}
                <span className="fw-bolder">best salon management software </span>
                for your business and how it can save you money while increasing your profits.
              </p>
            </CRow>
            <CRow className="mt-2 fs-5 lh-base m-auto">
              {" "}
              <h3>1. No Hidden Fees for WhatsApp API</h3>
              <p>
                Many salon management software charge extra fees for WhatsApp API, a vital feature
                to send appointment reminders, invoices, and promotions. With SalonBoost, you get{" "}
                <span className="fw-bolder">free WhatsApp API </span> for transactional messages.
                This means you don’t need to worry about{" "}
                <span className="fw-bolder"> additional charges </span>
                additional charges that can add up quickly.
              </p>
              <ul
                className="ps-4"
                style={{
                  listStylePosition: "inside",
                }}
              >
                <li>
                  <span className="fw-bolder"> Miosalon Software </span>
                  charges ₹1200 for 1000 whatsapp transactional messages which are used for sending
                  invoice, appointment and reminders over the whatsapp. If you are sending 2000
                  messages in a month, you will be shelling out 2400 rupees per month + software
                  charge of 4000 rupees per month.
                </li>
                <CImage
                  src={landing}
                  fluid
                  alt="Landing Image 1"
                  className="mt-4 mb-4 img-thumbnail mx-auto d-block responsive-image"
                  style={{
                    maxWidth: "100%", // Medium size for web view
                    width: "100%", // Full width for mobile
                    height: "auto",
                  }}
                />
                <li>
                  <span className="fw-bolder mt-4 mmt-2 fs-5 lh-base"> Zylu App </span>
                  charges 10K per annum as whatsapp platform fees + 1000 as set-up fees and on top
                  of this you have to pay a cost for each message. SalonBoost keeps your whatsapp
                  transactional messaging costs zero, and that’s a huge advantage for small to
                  medium salons looking to keep expenses low.
                </li>
              </ul>
              <CImage
                src={landing2}
                fluid
                alt="Landing Image 2"
                className="mt-2 img-thumbnail responsive-image "
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  margin: "0 auto",
                }}
              />
              <p className="mt-4">
                You can verify the same from their website -
                <a
                  href="https://www.zylu.co/grow-with-whatsapp-marketing/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.zylu.co/grow-with-whatsapp-marketing/
                </a>
              </p>
              <p>
                <span className="fw-bolder"> SalonBoost,</span>
                keeps your <span className="fw-bolder">whatsapp transactional </span>
                messaging costs <span className="fw-bolder">zero,</span>
                and that’s a huge advantage for small to medium salons looking to keep expenses low.
              </p>
            </CRow>
            <CRow className="mt-2 fs-5 lh-base ">
              {" "}
              <h3>2. Marketing Support</h3>
              <p>
                With <span className="fw-bolder">SalonBoost</span>, you don’t just get salon
                management software, you get a{" "}
                <span className="fw-bolder">complete marketing solution</span> We provide:
              </p>
              <ul
                className="mms-1 ps-4 pe-4"
                style={{
                  marginLeft: "1rem",
                }}
              >
                <li>
                  <span className="fw-bolder">Free Social Media Evaluation:</span> We evaluate your
                  social media presence and provide a{" "}
                  <span className="fw-bolder">custom report</span> on how to improve engagement and
                  reach. We will evaluate your Google My Business, Instagram and Facebook account.
                </li>
                <li>
                  <span className="fw-bolder">Guide to Run Converting Insta Ads:</span> Learn how to
                  run Instagram ads that drive bookings. We offer a simple guide that shows you
                  step-by-step how to create ads that work for your salon.
                </li>
                <li>
                  <span className="fw-bolder">Sales Conversion Guide: </span> We provide actionable
                  tips and proven strategies to help you{" "}
                  <span className="fw-bolder">close more sales</span> and increase bookings.
                </li>
              </ul>
              <p>
                <span className="fw-bolder">Our Competitors </span>may offer basic features but{" "}
                <span className="fw-bolder">don't include these marketing tools</span> that can help
                grow your salon. You get <span className="fw-bolder">all this for free</span> with
                SalonBoost.
              </p>
            </CRow>
            <CRow className="mt-3 fs-5 lh-base">
              {" "}
              <h3>3. Access to Exclusive Community & Monthly Meetings</h3>
              <p>
                Being a part of a <span className="fw-bolder"> salon business community</span> is
                invaluable. At SalonBoost, we give you access to:
              </p>
              <ul
                className="ps-4 pe-4"
                style={{
                  marginLeft: "1rem",
                }}
              >
                <li>
                  <span className="fw-bolder">Monthly Community Meetings:</span> Discuss your
                  salon's challenges with other salon owners and learn from experts. We talk about
                  business growth strategies and marketing ideas that actually work.
                </li>
                <li>
                  <span className="fw-bolder">Salon WhatsApp Community: </span>
                  Join a nationwide community of salon owners in India to share tips, experiences,
                  and learn how others are tackling business challenges
                </li>
              </ul>
            </CRow>
            <CRow className="mt-3 fs-5 lh-base">
              {" "}
              <h3>4. Exceptional Support with SalonBoost</h3>
              <p>
                When you choose
                <span className="fw-bolder">SalonBoost,</span> you're not just getting software;
                you're getting a dedicated team to support your salon's success. Here's what our
                support package includes:
              </p>
              <ul
                className="ps-4 pe-4"
                style={{
                  marginLeft: "1rem",
                }}
              >
                <li>
                  <span className="fw-bolder">Chat / Email Support:</span>
                  Get quick resolutions to your queries via chat or email.
                </li>
                <li>
                  <span className="fw-bolder">Telephone Support:</span>Speak directly with our
                  support team for immediate assistance.
                </li>
                <li>
                  <span className="fw-bolder">Priority Support:</span>
                  As a premium customer, your issues are handled on priority
                </li>
                <li>
                  <span className="fw-bolder">Dedicated Account Manager:</span>
                  Receive personalized support with a dedicated account manager to help you maximize
                  SalonBoost's features for your salon.
                </li>
              </ul>
              <p>
                With SalonBoost, you’re never alone. Our support team ensures that your business
                runs smoothly and efficiently, allowing you to focus on what you do best—serving
                your clients.
              </p>
            </CRow>
            <CRow className="mt-3 fs-5 lh-base">
              {" "}
              <h3>5.Unbeatable Pricing and Limited Offers</h3>
              <p>
                We understand how hard it can be for smaller salons to afford premium salon
                management software. That’s why we offer{" "}
                <span className="fw-bolder">SalonBoost Premium</span> at a fraction of the price.
              </p>
              <ul className="ps-4 pe-4" style={{ marginLeft: "1rem" }}>
                <li>
                  Miosalon charges ₹4000/month for their premium package + Additional charges for
                  whatsapp transaction messages.
                </li>
                <li>Zylu App charges ₹2249/month with limited support and no free WhatsApp API.</li>
              </ul>
              <p>
                With SalonBoost, you can get the{" "}
                <span className="fw-bolder">entire premium package for just ₹999/month</span> when
                you sign up for the annual plan. Plus, enjoy the added benefits of{" "}
                <span className="fw-bolder"> free support</span>
              </p>
            </CRow>
            <CRow className="mt-4 mb-2 d-flex align-items-center fs-5 lh-base">
              <CCol xs={12} md={6}>
                <h3>6. Unmatched Guarantees with SalonBoost</h3>
                <p>
                  We at <span className="fw-bolder">SalonBoost</span> are confident in the value our
                  software brings to your salon, and we back this up{" "}
                  <span className="fw-bolder">with two solid guarantees:</span>
                </p>
                <ul style={{ marginLeft: "1.5rem" }}>
                  <li>
                    <span className="fw-bolder">15-Day Money-Back Guarantee:</span> If you’re not
                    completely satisfied within the first 15 days, we’ll give you a full refund.
                  </li>
                  <li>
                    <span className="fw-bolder">Unbeatable Price Guarantee:</span> No one can beat
                    our price for the features and support we offer.
                  </li>
                </ul>
              </CCol>
              <CCol xs={12} md={6} className="text-center">
                <CImage
                  src={landing3}
                  fluid
                  alt="Landing Image 3"
                  className="mt-3"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "250px",
                    margin: "0 auto",
                  }}
                />
              </CCol>
            </CRow>

            <CRow>
              <h2 className="fs-1 mt-4 mmt-2">Comparison Summary</h2>
              <CCol className="mt-4 d-flex align-items-center fs-5 lh-base">
                <CTable responsive bordered>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell className="fw-bold">Feature/Software</CTableHeaderCell>
                      <CTableHeaderCell className="fw-bold">MioSalon</CTableHeaderCell>
                      <CTableHeaderCell className="fw-bold">Zylu</CTableHeaderCell>
                      <CTableHeaderCell className="fw-bold">Marg</CTableHeaderCell>
                      <CTableHeaderCell className="fw-bold">SalonBoost</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="fw-bold">Best For</CTableDataCell>
                      <CTableDataCell>Well-established salons</CTableDataCell>
                      <CTableDataCell>Small to medium size</CTableDataCell>
                      <CTableDataCell>Not suitable for modern salons</CTableDataCell>
                      <CTableDataCell>Salon growth & automation</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-bold">Pricing</CTableDataCell>
                      <CTableDataCell>High</CTableDataCell>
                      <CTableDataCell>Affordable</CTableDataCell>
                      <CTableDataCell>Moderate</CTableDataCell>
                      <CTableDataCell>Best value for money</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-bold">Ease of Use</CTableDataCell>
                      <CTableDataCell>High</CTableDataCell>
                      <CTableDataCell>
                        Moderate (Mobile-focused, not laptop-friendly)
                      </CTableDataCell>
                      <CTableDataCell>Low (Complex UI/UX)</CTableDataCell>
                      <CTableDataCell>High (Clean, modern UI/UX)</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-bold">Marketing Tools</CTableDataCell>
                      <CTableDataCell>High - With high cost</CTableDataCell>
                      <CTableDataCell>Advanced (Marketing automation)</CTableDataCell>
                      <CTableDataCell>None</CTableDataCell>
                      <CTableDataCell>
                        Advanced (Automated lead nurturing, WhatsApp & social media integration)
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-bold">Scalability</CTableDataCell>
                      <CTableDataCell>High</CTableDataCell>
                      <CTableDataCell>High</CTableDataCell>
                      <CTableDataCell>Low (Not cloud-based)</CTableDataCell>
                      <CTableDataCell>High (Supports salons of all sizes)</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-bold">Security & Compliance</CTableDataCell>
                      <CTableDataCell>Standard</CTableDataCell>
                      <CTableDataCell>Limited - Not mentioned</CTableDataCell>
                      <CTableDataCell>Low (Old technology)</CTableDataCell>
                      <CTableDataCell className="fw-bold">
                        Advanced (SSL, cloud-based, data encryption, compliance-ready)
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-bold">Technology</CTableDataCell>
                      <CTableDataCell>Cloud-based, good design</CTableDataCell>
                      <CTableDataCell>Mobile-first, sometimes slow</CTableDataCell>
                      <CTableDataCell>Outdated, offline</CTableDataCell>
                      <CTableDataCell className="fw-bold">
                        Latest technology, fast & secure
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-bold">Customer Support</CTableDataCell>
                      <CTableDataCell>Standard</CTableDataCell>
                      <CTableDataCell>Standard</CTableDataCell>
                      <CTableDataCell>Limited</CTableDataCell>
                      <CTableDataCell className="fw-bold">
                        Priority support & salon business consulting
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCol>
            </CRow>

            <CRow className="mt-5 d-flex align-items-center fs-5 lh-base">
              <CCol>
                <h2>Why Choose SalonBoost?</h2>
                <p>
                  Designed specifically to grow salon businesses Marketing automation for lead
                  generation & customer retention Secure, cloud-based with latest encryption &
                  security certifications Best pricing in the industry for the value offered Easy to
                  use on both desktop & mobile
                </p>
              </CCol>
            </CRow>

            <CRow className="mt-5 d-flex align-items-center fs-5 lh-base">
              <CCol>
                <CCard className=" w-lg-75 mb-3">
                  <CCardBody>
                    <CCardTitle>
                      Scale Your Salon with SalonBoost – Book a Free Strategy Session! Unlock the
                      power of automation, marketing, and smart salon management.
                    </CCardTitle>
                    <CCardText>
                      <p> 🔹 Increase revenue by 30% with our proven salon growth strategies</p>
                      <p> 🔹 Automate appointments, reminders, and client follow-ups</p>
                      <p>
                        🔹 Improve customer retention with targeted marketing & loyalty programs
                      </p>
                      <p>🔹 Streamline operations for better efficiency & transparency</p>
                      <p>🔹 Manage staff & inventory effectively to reduce losses</p>
                      <p>🔹 Leverage the latest technology for a seamless salon experience</p>

                      <p>📅 Ready to take your salon to the next level?</p>
                    </CCardText>
                    <button
                      className="bttn mmt-1 mb-3"
                      onClick={() => {
                        // setDemoModalVisible(true);
                        openWhatsApp();
                      }}
                    >
                      Book Your Free Strategy Session Now!
                    </button>
                    <p>
                      💡 Let’s build a profitable, automated, and thriving salon business together!
                    </p>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </div>
        </div>
        <Footer showTrustpilot={false} showFreetrial={false} />
      </div>
    </>
  );
};

export default WhySalonboost;
