import React from "react";
import { CRow, CCol, CImage,CButton } from "@coreui/react";
import landing4 from "../../assets/images/landing/4.webp";

const phoneNumber = "7090515255";
  const message = "From Website - I am interested in salonboost offer";
  const openWhatsApp = () => {
    const whatsappLink = `https://wa.me/${phoneNumber.replace(
      /[-+ ]/g,
      ""
    )}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };

const Cover = () => {
  return (
    <>
      <CRow
        className="d-flex align-items-start fs-5 lh-base w-lg-75 m-auto ps-lg-5 pe-lg-5 "
        style={{ backgroundColor: "#f0f0f0" }}
      >
        <CCol className="p-2" xs={12} md={6} >
          <h1  className="fs-1 fw-bolder p-2"
          style={{ color: "#673DE6"}}>
            Up to 80% off:
          </h1>
          <h2 className="mt-1 mb-1 p-2 " style={{ color: "#2F1C6A" }}>
            We Challenge You to{" "}
            <span
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "2px 5px",
                display: "inline-block",
              }}
            >
              Find a Better Deal!
            </span>
          </h2>
          <p className="fs-5 p-2  ">
            SalonBoost offers the{" "}
            <span className="fw-bolder fs-2 ">Best price in the industry</span>{" "}
            with{" "}
            <span className="fw-bolder">
              free WhatsApp API for transaction messages.
            </span>{" "}
            No one can beat our value!
          </p>
        
          <div className="d-flex fs-5 align-items-center p-2">
            <CButton
              color="primary"
              variant="outline"
              style={{
                borderRadius: "5px",
              }}
              onClick={() => {
               
                openWhatsApp(); 
              }}
            >
              Start now
            </CButton>
          </div>

          <p className="mt-3 d-flex p-2">
            <img
              src="/images/svg/Shieldcheck.svg"
              alt="Check Shield"
              style={{ width: "20px", height: "25px", marginRight: "8px" }}
            />
            <small className="text-muted" style={{ color: "#888" }}>
              15-day money-back guarantee
            </small>
          </p>
          <div>
            <img
              src="/images/Ratings.webp"
              alt="Ratings"
              style={{ width: "80%", height: "auto", display: "block" }}
            />
          </div>
        </CCol>
        <CCol
          xs={12}
          md={6}
          className="text-center order-0 order-md-1 mb-4 mb-md-0"
        >
          <CImage
            src={landing4}
            fluid
            alt="Landing Image 2"
            className="mt-3"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "900px",
              margin: "0 auto",
            }}
          />
        </CCol>
      </CRow>
    </>
  );
};

export default Cover;
