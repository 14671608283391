import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ showTrustpilot = true, showFreetrial = true }) => {
  const socialLinks = [
    {
      name: "Facebook",
      url: "https://www.facebook.com/salonboost.software",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/salonboost.software/",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/salonboost-software/",
    },
    {
      name: "Twitter",
      url: "https://x.com/BoostSalon00",
    },
  ];

  return (
    <>
      {showTrustpilot && (
        <div
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="56278e9abfbbba0bdcd568bc"
          data-businessunit-id="670d479f101647bcdbfe3511"
          data-style-height="52px"
          data-style-width="100%"
        >
          <a
            className="trustpilot-widget-button"
            href="https://www.trustpilot.com/review/salonboost.online"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      )}
      {showFreetrial && (
        <section className="cta-bottom">
          <h2>Get 15 days Free Trial</h2>
          <p>Start using SalonBoost now and experience our premium features free for 15 days!</p>
          <Link to="/register" className="cta-button">
            Claim Your Free Trial
          </Link>
        </section>
      )}
      <footer style={styles.footer}>
        <div style={styles.container}>
          <div style={styles.column}>
            <h3 style={styles.heading}>Our Company</h3>
            <ul style={styles.list}>
              {[
                "About Us",
                "Leadership",
                "Terms and Conditions",
                "Refund Policy",
                "Write for us",
                "Contact Us",
                "Blog",
              ].map((item) => (
                <li key={item} style={styles.listItem}>
                  <Link to={`/${item.toLowerCase().replace(/ /g, "-")}`} style={styles.link}>
                    {item}
                  </Link>
                </li>
              ))}
              <li style={styles.listItem}>
                <a href="/sitemap.xml" style={styles.link}>
                  Sitemap
                </a>
              </li>
            </ul>
          </div>

          <div style={styles.column}>
            <h3 style={styles.heading}>Other Links</h3>
            <ul style={styles.list}>
              {[
                "Salon Scheduling Software",
                "Hair Salon Scheduling Software",
                "Barber Shop Scheduling Software",
                "Massage Scheduling Software",
                "Beauty Salon Booking Software",
                "Makeup Artist Booking Software",
                "Spa Scheduling Software",
                "Nail Salon Scheduling Software",
                "Bridal Salon Scheduling Software",
              ].map((item) => (
                <li key={item} style={styles.listItem}>
                  <a href="#" style={styles.link}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div style={styles.column}>
            <h3 style={styles.heading}>Who can Use it?</h3>
            <ul style={styles.list}>
              {[
                "Barber Shops",
                "Hair Salons",
                "Massage Therapy",
                "Nail Salon",
                "Spas",
                "Bridal Salon",
                "Medical Spa Software",
                "Aesthetic Skin Clinic",
                "Tattoo Artist Software",
                "Salon Booth For Renter",
                "Tanning Salon Software",
                "Pet Grooming",
              ].map((item) => (
                <li key={item} style={styles.listItem}>
                  <a href="#" style={styles.link}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div style={styles.column}>
            <h3 style={styles.heading}>Our Resources</h3>
            <ul style={styles.list}>
              {[
                "Videos",
                "Help Center",
                "Support",
                "Security",
                "Updates",
                "Case Studies",
                "Client Testimonials",
                "Affiliate Partnership",
                "Compare Us",
              ].map((item) => (
                <li key={item} style={styles.listItem}>
                  <a href={item === "Security" ? "/security" : "#"} style={styles.link}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div style={styles.column}>
            <h3 style={styles.heading}>Connect with us</h3>
            <ul style={styles.list}>
              {socialLinks.map((social) => (
                <li key={social.name} style={styles.listItem}>
                  <a href={social.url} style={styles.link} target="_blank" rel="noreferrer">
                    {social.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>

      <footer>
        <p>&copy; {new Date().getFullYear()} SalonBoost. All rights reserved.</p>
      </footer>
    </>
  );
};

const styles = {
  footer: {
    backgroundColor: "#000000",
    color: "#fff",
    padding: "40px 20px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "40px",
    maxWidth: "1200px",
    margin: "auto",
  },
  column: {
    flex: "1 1 200px",
  },
  heading: {
    marginBottom: "20px",
  },
  list: {
    listStyle: "none",
    padding: "0",
  },
  listItem: {
    marginBottom: "10px",
  },
  link: {
    color: "#bbb",
    textDecoration: "none",
  },
};

export default Footer;
